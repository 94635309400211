
import { Base64 } from 'js-base64';
import {
    withRouter
  } from "react-router-dom";  

import * as qs from 'query-string';
import Cookies from 'universal-cookie';

function Landing(props) {

    const defaultRedirectUrl = 'https://stylist.co.uk';
    const defaultMsg = 'Hello stranger!';

    const data = qs.parse(props.location.search);
    if (!data.for) {
        window.location.href = defaultRedirectUrl;
        return (<div>{defaultMsg}</div>);
    }

    const clientInfo = qs.parse(Base64.decode(data.for));
    if (!clientInfo.projectId || !clientInfo.client || !clientInfo.logoUrl) {
        window.location.href = defaultRedirectUrl;
        return (<div>{defaultMsg}</div>);
    }

    const cookies = new Cookies();
    
    cookies.set('projectId', clientInfo.projectId, { path: '/' });
    cookies.set('logoUrl', clientInfo.logoUrl, { path: '/' });
    cookies.set('client', clientInfo.client, { path: '/' });

    return (
    <div className="container py-4">
        <h5>Your commercial data report is ready!</h5>
        <a className="btn btn-primary" href="/facebook">Click here to view your report</a>
    </div>
    );
}

export default withRouter(Landing);