
import './../App.css';
import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Generator() {

  const [link, setLink] = useState(false);
  const [formData, setFormData] = useState({client:'', logoUrl:'', projectId:''});
  const [isValid, setIsValid] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const defaultLogoUrl = "https://www.thestylistgroup.com/_assets/85ec732fa6.png";

  const generateLink = () => {

    setIsValid(true);

    if (!formData.client || !formData.projectId) {
      setIsValid(false);
      return;
    }

    if (!formData.logoUrl) {
      formData.logoUrl = defaultLogoUrl;
    }

    const dataString = 'projectId='+encodeURIComponent(formData.projectId)+'&client='+encodeURIComponent(formData.client)+'&logoUrl='+encodeURIComponent(formData.logoUrl);
    setLink('https://commercial-report.stylist.co.uk/?for='+Base64.encode(dataString));

  }

  return (
    <div className="container">

      <div className="mb-3">
        <label htmlFor="client-name" className="form-label">Client Name *</label>
        <input type="text" className="form-control" id="client-name" placeholder="Coca Cola" value={formData.client} onChange={e => setFormData({ ...formData, client: e.target.value })} />
      </div>

      <div className="mb-3">
        <label htmlFor="logo-url" className="form-label">Client Logo URL</label>
        <input type="text" className="form-control" id="logo-url" placeholder={defaultLogoUrl} value={formData.logoUrl} onChange={e => setFormData({ ...formData, logoUrl: e.target.value })} />
      </div>

      <div className="mb-3">
        <label htmlFor="project-id" className="form-label">Project ID *</label>
        <input type="text" className="form-control" id="project-id" placeholder="46293" value={formData.projectId} onChange={e => setFormData({ ...formData, projectId: e.target.value })} />
      </div>

      <div className="mb-3">
        <button className="btn btn-primary" onClick={generateLink}>Generate Report</button>
      </div>

      { !isValid &&
        <div className="mb-3">
          <small className="text-danger">* Client Name and Project Id are required...</small>
        </div>
      }

      { link &&
        <div className="mb-3">
          <h4>Report generated!</h4>
          <input type="text" className="form-control" value={link} />

          <CopyToClipboard text={link} onCopy={() => setLinkCopied(true)}>
            <button className="btn btn-success my-2">Copy to clipboard</button>
          </CopyToClipboard>

          { linkCopied && <span className="text-danger"> Link copied!</span>}

        </div>
      }
    </div>
  );
}

export default Generator;
