
import './../App.css';
import {
    useLocation,
    withRouter
  } from "react-router-dom";

import Cookies from 'universal-cookie';

function Navbar(props) {

    let location = useLocation();

    const moatSelected = location.pathname.indexOf('/moat') !== -1;
    const gamSelected = location.pathname.indexOf('/gam') !== -1;
    const emailsSelected = location.pathname.indexOf('/emails') !== -1;
    const facebookSelected = location.pathname.indexOf('/facebook') !== -1;
    const permutiveSelected = location.pathname.indexOf('/permutive') !== -1;
    const ga360Selected = location.pathname.indexOf('/ga360') !== -1;

    const cookies = new Cookies();

    const projectId = cookies.get('projectId');
    const logoUrl = cookies.get('logoUrl');
    const client = cookies.get('client');

    const defaultRedirectUrl = 'https://stylist.co.uk';
    const defaultMsg = 'Hello stranger!';

    if (!projectId || !logoUrl || !client) {
      window.location.href = defaultRedirectUrl;
      return (<div>{defaultMsg}</div>);
  }

  return (

    <div>
      for <img src={logoUrl} width="100px" alt="logo URL" className="py-2"/>
      <h3>Project ID: {projectId}</h3><br/>

      <ul className="nav nav-tabs">
          <li className="nav-item">
              <a className={facebookSelected ? "nav-link active" : "nav-link"} aria-current="page" href="/facebook">Facebook</a>
          </li>
          <li className="nav-item">
              <a className={moatSelected ? "nav-link active" : "nav-link"} href="/moat">MOAT</a>
          </li>
          <li className="nav-item">
              <a className={gamSelected ? "nav-link active" : "nav-link"} href="/gam">GAM</a>
          </li>
          <li className="nav-item">
              <a className={emailsSelected ? "nav-link active" : "nav-link"} href="/emails">Emails</a>
          </li>
          <li className="nav-item">
              <a className={ga360Selected ? "nav-link active" : "nav-link"} href="/ga360">GA360</a>
          </li>
          <li className="nav-item">
              <a className={permutiveSelected ? "nav-link active" : "nav-link"} href="/permutive">Permutive</a>
          </li>
      </ul>
    </div>

  );
}

export default withRouter(Navbar);
