
import './../App.css';

function MOAT(props) {

  const sign = require('jwt-encode');
  const sharingToken = 'd762d0d0-6c88-4158-8dd2-2247089e4b28';
  const hash = {
    sharing_token: sharingToken,
    exp: Date.now() + 7200,
    fixed_parameters : {
      projectId:props.projectId
    }
  }
  const token = sign(hash,props.ck);

  return (
    <iframe title="MOAT" frameBorder="0" allowFullScreen height="900" width="100%" src={'https://dashboards.cluvio.com/dashboards/v5dy-lgvv-vpz3/shared?sharingToken='+sharingToken+'&sharingSecret='+token}></iframe>
  );
}

export default MOAT;