
import './../App.css';

function GAM(props) {

  const sign = require('jwt-encode');
  const sharingToken = 'e0e1bdc0-7604-43fa-9335-e0c8058e1c77';
  const hash = {
    sharing_token: sharingToken,
    exp: Date.now() + 7200,
    fixed_parameters : {
      projectId:props.projectId
    }
  }
  const token = sign(hash,props.ck);

  return (
    <iframe title="GAM" frameBorder="0" allowFullScreen height="900" width="100%" src={'https://dashboards.cluvio.com/dashboards/z926-3n44-1pdr/shared?sharingToken='+sharingToken+'&sharingSecret='+token}></iframe>
  );
}

export default GAM;