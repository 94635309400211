
import './../App.css';

function Permutive(props) {

  const sign = require('jwt-encode');
  const sharingToken = '33b8f7ca-192e-4414-974a-85814bfd1be8';
  const hash = {
    sharing_token: sharingToken,
    exp: Date.now() + 7200,
    fixed_parameters : {
      projectId:props.projectId
    }
  }
  const token = sign(hash,props.ck);

  return (
    <iframe title="Permutive" frameBorder="0" allowFullScreen height="900" width="100%" src={'https://dashboards.cluvio.com/dashboards/w13y-jwd4-lp7r/shared?sharingToken='+sharingToken+'&sharingSecret='+token}></iframe>
  );
}

export default Permutive;