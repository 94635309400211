
import './../App.css';

function Ga360(props) {

  const sign = require('jwt-encode');
  const sharingToken = '513966be-1dda-4e3c-8a92-4daa42eb64cb';
  const hash = {
    sharing_token: sharingToken,
    exp: Date.now() + 7200,
    fixed_parameters : {
      projectId:props.projectId
    }
  }
  const token = sign(hash,props.ck);

  return (
    <iframe title="GA360" frameBorder="0" allowFullScreen height="900" width="100%" src={'https://dashboards.cluvio.com/dashboards/ke7y-8z75-1y0d/shared?sharingToken='+sharingToken+'&sharingSecret='+token}></iframe>
  );
}

export default Ga360;